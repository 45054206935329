import React from "react"
import { injectIntl } from "gatsby-plugin-intl"


const ProductIcons = ({ icons, intl }) => {
    const actualIcons = icons.filter(icon => !!icon)
    return (
        <div className="c-producticons">
            {actualIcons.map(icon => (
                <div className="c-producticons__box">
                    <img
                        className="c-producticons__icon"
                        src={`/icons/${icon}.svg`}
                        alt=""
                        style={{ width: "40px", height: "40px", marginRight: "7px" }}
                    />
                    <span className="c-producticons__text">{intl.formatMessage({ id: `icons.${icon}` })}
                    </span>
                </div>
            ))}

        </div>
    )
}

export default injectIntl(ProductIcons)
