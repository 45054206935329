import React, { useEffect, useState, useContext } from "react"
import { navigate, injectIntl } from "gatsby-plugin-intl"

import { localize, createMarkup, getImageUrl, createAmazonLink } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductLeaf from "../components/product-leaf"
import Image from "../components/image"
import SpecsBox from "../components/specs-box"
import ProductBox from "../components/product-box"
import Carousel from "../components/carousel"
import Gallery from "../components/gallery"

import { UilImport } from "@iconscout/react-unicons"

import ProductIcons from "../components/product-icons"

// mapping da colore magento a colore gradient
const colors = {
  "#d60dd6": "linear-gradient(45deg, #000361 50%, #b8b8b8 50%)", // blu silver
  "#8bfa03": "linear-gradient(45deg, #000000 50%, #b8b8b8 50%)", // nero silver
  "#1a6dd9": "#000361", // blu
  "#b8b8b8": "#b8b8b8", // silver
  "#000000": "#000000" // nero
}

const ProductPage = ({ pageContext, intl }) => {
  // const intl = pageContext.intl
  const productData = pageContext.product
  const relatedData = pageContext.relatedProduct
  const sameCatProducts = pageContext.sameCatProducts

  const [productPrice, setProductPrice] = useState("")
  const [productLink, setProductLink] = useState("")

  const [formattedProd, setFormattedProd] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})
  const addToCartEnabled = true

  useEffect(() => {
    if (productData.shop_link) {
      setProductLink(productData.shop_link)
    } else {
      setProductLink(createAmazonLink(intl.locale, productData.asin))
    }
  }, [])

  const product = localize(productData, intl.locale)
  const relatedProduct = localize(relatedData, intl.locale)
  const catProducs = sameCatProducts.map(p => localize(p, intl.locale))

  const carouselImages =
    product.additional_images.length > 0
      ? product.additional_images.map(
        img => img.localFile.childImageSharp.fixed.src
      )
      : undefined
  if (carouselImages)
    carouselImages.unshift(product.image.localFile.childImageSharp.fixed.src)

  const galleryImages =
    product.gallery.length > 0
      ? product.gallery.map(img => {
        return {
          fullscreen: img.localFile?.large?.fixed.src,
          original: img.localFile?.medium?.fixed.src,
          thumbnail: img.localFile?.small?.fixed.src,
        }
      })
      : undefined

  const addToCart = async () => {
    console.log('VADO A', productLink)
    window.open(productLink, '_blank');

  }

  const options = formattedProd?.swatch || []

  const changeOption = (code, value) => {
    setSelectedOptions({ ...selectedOptions, [code]: value })
    console.log('opzioni', selectedOptions)
  }

  const productBody = product.long_description

  const bodyParts = (productBody || "").split(/\[\[(.*)\]\]/g)

  return (
    <Layout name="p-product">
      <Seo
        title={product.name}
        description={product.short_description}
        imageUrl={getImageUrl(product.image)}
      />
      <div className="p-product__header">
        <div className="a-container">
          <div className="p-product__header-content">
            <div className="p-product__header-image position-relative">
              <div className="a-dots a-dots--alt middle-left a-dots--large"></div>
              {!carouselImages && <Image image={product.image} center />}
              {carouselImages && (
                <Carousel
                  images={carouselImages}
                  slides={1}
                  breakpoints={null}
                />
              )}
            </div>
            <div className="p-product__header-info">
              <h1>{product.name}</h1>
              <div className="p-product__abstract">
                {product.short_description}
              </div>
              <div className="p-product__options">
                {options.map(opt => {
                  return (
                    <div className="p-product__options__wrap">
                      <span>{opt.label}</span>
                      <div className="p-product__options__items">
                        {opt.values.map(value => (
                          <span
                            className={
                              [opt.attribute_code === "color"
                                ? "p-product__options__color"
                                : "p-product__options__size",
                              Object.values(selectedOptions).includes(value.uid) ? "is-active" : ""].join(" ")
                            }
                            style={opt.attribute_code === "color" ? { background: colors[value.swatch_data?.value] } : {}}
                            onClick={() =>
                              changeOption(opt.attribute_code, value.uid)
                            }
                          >
                            {opt.attribute_code !== "color" && value.label}
                            {/* {JSON.stringify(opt)} */}
                          </span>
                        ))}
                      </div>
                    </div>
                  )
                })}
                {/* {options.map(opt => {
                  return (
                    <Dropdown
                      key={opt.label}
                      placeholder={opt.label}
                      options={opt.values.map(value => ({
                        label: value.label,
                        data: value.uid,
                      }))}
                      onChange={value =>
                        changeOption(opt.attribute_code, value.data)
                      }
                    />
                  )
                })} */}
              </div>
              {product.asin && productLink && (
                <div className="p-product__header-price">
                  {productPrice && (
                    <span className="p-product__price">{productPrice}</span>
                  )}
                  <a
                    onClick={addToCartEnabled && addToCart}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={
                      addToCartEnabled
                        ? "a-btn a-btn--cta"
                        : "a-btn  a-btn--disabled"
                    }
                    style={!productPrice ? { marginLeft: 0 } : {}}
                  >
                    {intl.formatMessage({ id: "terms.buy" })}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="a-container">
        <div className="p-product__content">
          <div className="p-product__main">
            {bodyParts.map(part => {
              if (part === "icone") {
                return <ProductIcons icons={product.icone_pack} />
              }
              return <div dangerouslySetInnerHTML={createMarkup(part)} />
            })}

            {galleryImages && (
              <div style={{ marginTop: "50px" }}>
                <Gallery images={galleryImages} />
              </div>
            )}
          </div>
          <div className="p-product__sidebar">
            {(product.manual_download || product.app_manual_download) && (
              <div className="p-product__download-buttons">
                {product.manual_download && (
                  <a
                    href={product.manual_download}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-product__download__item"
                  >
                    <span className="p-product__download__icon">
                      <UilImport />
                    </span>
                    {intl.formatMessage({ id: "terms.download_manual" })}
                  </a>
                )}
                {product.app_manual_download && (
                  <a
                    href={product.app_manual_download}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-product__download__item "
                  >
                    <span className="p-product__download__icon">
                      <UilImport />
                    </span>
                    {intl.formatMessage({ id: "terms.download_app_manual" })}
                  </a>
                )}
              </div>
            )}
            {product.specs && (
              <SpecsBox
                product={product}
                field="specs"
                color="#EFF5FA"
                textColor="#152340"
              />
            )}
            {product.consigli && (
              <SpecsBox
                product={product}
                field="consigli"
                color="rgba(29, 187, 130, 0.05)"
                textColor="#1DBB82"
              />
            )}
            {Object.keys(relatedProduct).length > 0 && (
              <ProductBox product={relatedProduct} isRelated={true} />
            )}
          </div>
        </div>
      </div>
      <div>
        {catProducs.length > 1 && (
          <div className="a-container p-product-others a-container--fluidMobile">
            <h4 className="a-section__title">
              {intl.formatMessage({ id: "terms.same_family" })}
            </h4>
            <div className="p-product-others__content">
              {catProducs.map(product => {
                return (
                  <>
                    <ProductLeaf
                      options={{ size: 165, compact: true }}
                      onClick={() => navigate(`/prodotto/${product.item_id}`)}
                      item={product}
                    />
                  </>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default injectIntl(ProductPage)
