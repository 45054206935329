import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

const ProductBox = ({ product, field, title, color, textColor, intl }) => {
  const specs = product[field] ? product[field].split("\n").join("\n\n") : ""
  return (
    <div className="c-specs-box" style={{ backgroundColor: color || 'white' }}>
      <div className="c-specs-box__header" style={{ color: textColor, borderBottom: `1px solid ${textColor}` }}>
        {title || intl.formatMessage({ id: `terms.${field}` })}
      </div>
      <div className="c-specs-box__content">{specs}</div>
    </div>
  )
}

export default injectIntl(ProductBox)
