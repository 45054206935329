import React from "react"
import ImageGallery from "react-image-gallery"
import {
  UilAngleRight,
  UilAngleLeft,
  UilSearchPlus,
  UilSearchMinus,
} from "@iconscout/react-unicons"

const IconStyle = {
  color: "#004b93",
  backgroundColor: "transparent",
  border: "0",
  cursor: "pointer",
  outline: "none",
  position: "absolute",
  zIndex: "4",
}
const Gallery = ({ images }) => {
  const items = images
  const renderLeftNav = (onClick, disabled) => {
    return (
      <button
        className="image-gallery-left-nav"
        style={IconStyle}
        disabled={disabled}
        onClick={onClick}
      >
        <UilAngleLeft size="60" />
      </button>
    )
  }
  const renderRightNav = (onClick, disabled) => {
    return (
      <div
        className="image-gallery-right-nav"
        style={IconStyle}
        disabled={disabled}
        onClick={onClick}
      >
        <UilAngleRight size="60" />
      </div>
    )
  }

  const renderFullScreenBtn = (onClick, isFullscreen) => {
    return (
      <div
        type="button"
        className={`image-gallery-fullscreen-button`}
        style={IconStyle}
        onClick={onClick}
      >
        {!isFullscreen ? (
          <UilSearchPlus size="40" />
        ) : (
          <UilSearchMinus size="40" />
        )}
      </div>
    )
  }

  return (
    <ImageGallery
      showPlayButton={false}
      items={items}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      renderFullscreenButton={renderFullScreenBtn}
    />
  )
}

export default Gallery
